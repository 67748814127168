<template>
    <v-app>
        <NavBar style="max-height: 50px; margin-bottom: 10px" />
        <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                <SideBar style="max-height: 50px" :pageName="pageName"/>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
                <div class="scrollDiv">
                    <div class="d-flex flex-row align-center my-6" style="height: 48px;">
                        <img :src="back_button" class="mr-6" @click="goToBack()"/>
                        <span class="commonHomeFont">
                            Edit Patient Information
                        </span>
                    </div>
                    <!-- <div class="UpdateUserManagementInnerClass">
                        <div class="updatePhoneNumberFontClass">Change Phone Number</div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div style="width: 100%">
                                <div class="mb-2" style="text-align: left">Old Phone Number<span class="astreikClass">*</span></div>
                                <div class="MobileNumberClass">
                                    <div class="ml-2">
                                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/myanmar_flag.svg" align="left" style="width:26px ;height:26px;" />
                                    </div>
                                    <div class="ml-2">+95</div>
                                    <div class="ml-2">
                                        <v-text-field 
                                        background-color="#F4F6FA" 
                                        v-model="mobileNumber" 
                                        :rules="mobileNumberRules" placeholder="09*********" />
                                    </div>
                                </div>
                            </div>
                            <div style="width: 100%">
                                <div class="mb-2" style="text-align: left">New Phone Number<span class="astreikClass">*</span></div>
                                <div class="MobileNumberClass">
                                    <div class="ml-2">
                                        <img src="https://d3vsnl8idgwrlw.cloudfront.net/myanmar_flag.svg" align="left" style="width:26px ;height:26px;" />
                                    </div>
                                    <div class="ml-2">+95</div>
                                    <div class="ml-2">
                                        <v-text-field 
                                        v-model="newMobileNumber" 
                                        :rules="mobileNumberRules" 
                                        background-color="#F4F6FA"
                                        placeholder="09*********" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="!userExistMessageFlag" class="getOTPClass" @click="validatePhoneNumber()" style="cursor: pointer;">
                            <span class="getOTPFontClass">
                                Get OTP
                            </span>
                        </div>
                        <div class="dashedLineClassInNewUser"></div>
                    </div> -->
                    <div class="UpdateUserManagementInnerClass my-8">
                        <div class="d-flex flex-row justify-space-between" style="width: 100%">
                            <div class="updatePhoneNumberFontClass">Addresses</div>
                            <div class="addMultipleAddressClass" @click="addMultipleAddress()">
                                <img :src="multipleAdds"/>
                                <span style="color: #FFFFFF"> 
                                Add Address
                                </span>
                            </div>
                        </div>
                        <div class="outerUpdateAddressClass" style="width:100%;">
                            <div v-for="(address, index) of addressList" :key="index" class="innerUpdateAddressClass" style="width:100%; justify-content: space-between;">
                                <div class="addressIndexClass">Address {{ index + 1 }}</div>
                                <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">House Number /Building Name<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-text-field 
                                            solo flat
                                            background-color="#F4F6FA" 
                                            v-model="address.buildingName" 
                                            :error-messages="v$.$error && v$.$errors[0].$property === 'buildingName' ? v$.$errors[0].$message: ''"
                                            placeholder="House Number /Building Name" />
                                        </div>
                                    </div>
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">Street Name / Locality<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-text-field 
                                            solo flat
                                            background-color="#F4F6FA" 
                                            v-model="address.streetName" 
                                            :error-messages="v$.$error && v$.$errors[0].$property === 'streetName' ? v$.$errors[0].$message: ''"
                                            placeholder="Street Name / Locality" />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">PinCode<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-text-field 
                                            solo flat
                                            background-color="#F4F6FA" 
                                            v-model="address.pinCode" 
                                            :error-messages="v$.$error && v$.$errors[0].$property === 'pinCode' ? v$.$errors[0].$message: ''"
                                            placeholder="PinCode" />
                                        </div>
                                    </div>
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">LandMark<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-text-field 
                                            solo flat
                                            background-color="#F4F6FA" 
                                            v-model="address.landMark" 
                                            :error-messages="v$.$error && v$.$errors[0].$property === 'landMark' ? v$.$errors[0].$message: ''"
                                            placeholder="LandMark" />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">Country<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-text-field
                                            solo flat disabled
                                            background-color="#F4F6FA" 
                                            v-model="address.country"
                                            placeholder="Country" />
                                        </div>
                                    </div>
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">State<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-autocomplete 
                                                solo flat
                                                placeholder="State" 
                                                background-color="#F4F6FA" 
                                                v-model="address.state" 
                                                :items="stateList"
                                                :error-messages="v$.$error && v$.$errors[0].$property === 'address.state' ? v$.$errors[0].$message: ''"
                                                item-value="id"
                                                item-text="name"
                                                required
                                                @change="changeState(address.state)"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                                    <div style="width:49%;">
                                        <div class="mb-2" style="text-align: left">Address Type<span class="astreikClass">*</span></div>
                                        <div>
                                            <v-radio-group row v-model="address.radioGroup" :error-messages="v$.$error && v$.$errors[0].$property === 'radioGroup' ? v$.$errors[0].$message: ''">
                                                <v-radio
                                                    v-for="n in addressTypes"
                                                    :key="n"
                                                    
                                                    :label="`${n}`"
                                                    :value="n"
                                                ></v-radio>
                                            </v-radio-group>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!userExistMessageFlag" class="getOTPClass" @click="updateUserAddress(address)" style="cursor: pointer;">
                                    <span class="getOTPFontClass">
                                        Update Changes
                                    </span>
                                </div>
                                <div class="dashedLineClassInNewUser"></div>
                            </div>
                        </div>
                    </div>
                    
                    <div v-if="confirmationFlag">
                        <DeleteConfirmationDialog :confirmationFlag="confirmationFlag" :itemType="itemType" @closeDialog="closeDialog" />
                    </div>
                    <div v-if="showMessageDialog">
                        <SuccessDialog :successObject="successObject" @closeSuccessDialog="closeSuccessDialog"/>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import{axios_auth_instance_epharmacy_admin} from '../../../utils/axios_utils';
import NavBar from '../../navbar.vue';
import SideBar from '../../sideBar.vue';
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
import { useVuelidate } from '@vuelidate/core'
import { axios_auth_instance_epharmacy_customer } from '../../../utils/axios_utils';
import { axios_auth_instance_admin } from '../../../utils/axios_utils';
export default {
setup() {
    return {
        v$: useVuelidate()
    }
},
name: "UpdateUserManagement",
components : {
    'NavBar': NavBar,
    'SideBar': SideBar,
    'DeleteConfirmationDialog': DeleteConfirmationDialog,
    'SuccessDialog': SuccessDialog,
},
computed: {
},
data() {
    return {
        addressList: [{}],
        address: {},
        userExistText: '',
        pageName: 'UpdateUserManagement',
        mobileNumberRules: [
            v => !!v || 'Mobile Number is required',
            v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
            v => v > 0 || 'Mobile number cannot be negative',
        ],
        successObject: {},
        showMessageDialog: false,
        itemType:'',
        iconObjects: [],
        segmentCacheData: [],
        promotionBanners: [],
        specializations: [],
        showPreviewOnBuilder: false,
        previewOnBuilderProps: {},
        bannerEditData: {},
        editBlockDetails: {},
        confirmationFlag: false,
        getBannerSegmentResponseData: {},
        addHeroBannerDialogFlag: false,
        cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
        editIcon: 'editIcon.svg',
        deleteIcon: 'deleteIcon.svg',
        back_button: 'back_button.svg',
        retryGrey: 'retryGrey.svg',
        multipleAdds: 'multipleAdds.svg',
        mobileNumber: '',
        newMobileNumber: '',
        userExistMessageFlag: false,
        userExistFlag: false,
        createNewUserText: '',
        buildingName: '',
        pinCode: '',
        landMark: '',
        streetName: '',
        radioGroup: '',
        state: '',
        addressTypes: ["Home", "Work", "Others"],
        stateList: [],
        countryList: [],
        country:'MYANMAR',
    }
},
methods: {
    async updateUserAddress(address) {
        let addressBody = {
            'addressId': address.addressID,
            'type': 'delivery',
            'name': 'Person Name',
            'street': address.buildingName,
            'street2': address.streetName,
            'addressTypeName': address.radioGroup,
            'partnerId': this.partnerID,
            'state_id': address.state,
            // 'deliveryPhoneNumber': address.,
            'pinCode': address.pinCode,
            'landmark': address.landMark
        }
        let updateUserAddressResponse = await axios_auth_instance_epharmacy_admin.post( "/admin/user/updateAddress", {
            phoneNumber: this.customer_current_number.toString(),
            customerDetails: this.customerDetails,
            addressList: this.addressList,
            address: addressBody
        });
        console.log('updateUserAddressResponse', updateUserAddressResponse);

    },
    changeState(state) {
    },
    addMultipleAddress() {
        this.addressList.push(1);
    },
    goToBack() {
    },
    async validatePhoneNumber() {
        try{
            let validatePhoneNumberResponse = await axios_auth_instance_epharmacy_admin.post( "/validateUserPhoneNumber", {
                phoneNumber: this.mobileNumber.toString()
            });
            if(validatePhoneNumberResponse && validatePhoneNumberResponse.data && validatePhoneNumberResponse.data.data) {
                if(validatePhoneNumberResponse.data.data === 'EXISTS') {
                    this.userExistFlag = true;
                    this.userExistText = '1 user linked';
                    this.createNewUserText = 'Edit User Details';
                } else {
                    this.userExistFlag = false;
                    this.userExistText = 'User does not exist';
                    this.createNewUserText = 'Create New User';
                }
                this.userExistMessageFlag = true;
            }
        } catch(error){
            console.error("An error occurred:", error);
            alert("Something went wrong.");
        }
    },
    closeSuccessDialog() {
        this.showMessageDialog = false;
    },
    async renderComponent() {	
        try{		
            let getUserDetails = await axios_auth_instance_epharmacy_admin.post(  "/admin/user/getUserDetails", {
                phoneNumber: this.customer_current_number.toString()
            });
            if(getUserDetails && getUserDetails.data && getUserDetails.data.data && getUserDetails.data.data.delivery_address) {
                this.customerDetails = getUserDetails.data.data;
                this.partnerID = getUserDetails.data.data.partner_id;
                this.pk = getUserDetails.data.data.pk;
                this.sk = getUserDetails.data.data.sk;
                let allAddresses = getUserDetails.data.data.delivery_address;
                let addressArray = [];
                for (let address of allAddresses) {
                    addressArray.push({
                        addressID: address.address_unique_id,
                        buildingName: address.house_number,
                        streetName: address.street_address,
                        landMark: address.landmark,
                        radioGroup: address.address_type_name, // === "Home" ? 1 : address.address_type_name === "Work" ? 2 : address.address_type_name === "Others" ? 3 : '',
                        country: "MYANMAR",
                        state: parseInt(address.state_id),
                        pinCode: address.pincode,
                    });
                }
                this.addressList = [];
                this.addressList = addressArray;
            }
        } catch(error){
            console.error("An error occurred:", error);
            alert("Something went wrong.");
        }
    },
    refreshPage() {
        window.location.reload();
    },
    closeDialog (valueFromEmit) {
        if (valueFromEmit.value) {
            if(valueFromEmit.itemType === 'Banner') this.deleteParticularBannerSegment();
            if(valueFromEmit.itemType === 'Block') this.deleteParticularBlockSegment();
            if(valueFromEmit.itemType === 'Article') this.deleteParticularArticleSegment();
            if(valueFromEmit.itemType === 'Hero Banner') this.deleteHeroBannerMethodCall();
        } else {
            this.confirmationFlag = false;
        }
    },
    closeCancelOperation() {
        this.addHeroBannerDialogFlag = false;
        this.editHeroBannerDialogFlag = false;
    },
},
async mounted() {
    try{
    this.currentUser = this.$cookies.get("token");
    var params = this.$route.params;
    var customer_id = params['customer_id'];
    this.customer_id = customer_id;
    if (!this.currentUser) {
        this.$router.push({
        name: "AdminLogin",
        });
    } else {
        var customerBody = {
            'customerId' : this.customer_id,
            'token': this.currentUser,
            'typeOfUser': "ADMIN"
          };
          await axios_auth_instance_admin.post("/readCustomer", customerBody)
          .then((customerResponse) => {
            this.customer_name = customerResponse.data.data.customer_name;
            this.customer_current_number = customerResponse.data.data.phoneNumber;
          })
          .catch((customerError) => {
            handleError(customerError, this.$router, 'customerError', 'AdminLogin', 'token');
          });
        let getStateCountryIdListResponse = await axios_auth_instance_epharmacy_customer.get('/address/stateCountryList');
        this.stateList = getStateCountryIdListResponse.data.data.listOfState;
        this.countryList = getStateCountryIdListResponse.data.data.listOfCountry;
        this.back_button = this.cloudFront + this.back_button;
        this.retryGrey = this.cloudFront + this.retryGrey;
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
        await this.renderComponent();
    }
    }catch(error){
        console.error("An error occurred:", error);
    }
},
}
</script>

<style>
.addNewSegmentsDiv {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.scrollDiv {
max-height: 900px;
overflow-y: scroll;
}
.dot {
display: flex;
align-items: center;
width: 80px;
height: 80px;
background-color: #000;
border-radius: 50%;
margin: 10px;
cursor: grab;
color: #fff;
}

.dot-handle {
width: 20px;
height: 20px;
background-color: #fff;
border-radius: 50%;
margin-right: 10px;
cursor: grab;
}
.draggable-item {
background-color: #f2f2f2;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 5px;
cursor: grab;
width: 100%;
}
.commonHomeFont{
height: 34px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
color: #333333;
flex: none;
order: 0;
flex-grow: 0;
}
.UpdateUserManagementInnerClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    isolation: isolate;
    width: 99%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.getOTPClass {
    display: flex;
    width: 250px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    background: var(--primary, #48ACEF);
}
.getOTPFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.mtb20 {
    margin-top: 20px;
    margin-bottom: 20px;
}
.updatePhoneNumberFontClass{
    color: var(--grays-gray-1, #333);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.flexJustifyAlignCenter {
display: flex;
justify-content: center;
align-items: center;
}
.bannerBlockArticleClass {
display: flex;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 2px 8px 8px 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.noUnderLineClass {
text-decoration: none !important;
}
.bannerBlockArticleCollapsibleClass{
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: space-between;
padding: 16px;
gap: 24px;
width: 1467px;
height: 64px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 2px 8px 8px 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.innerSpanBannerBlockArticleFontClass {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 24px;
gap: 10px;
width: 96px;
height: 32px;
border-radius: 4px;
flex: none;
order: 0;
flex-grow: 0;
}
.innerSpanFont {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #828282;
flex: none;
order: 0;
flex-grow: 0;
}

.MobileNumberClass {
    display: flex;
    width: 98%;
    height: 44px;
    padding: 12px 13px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--grays-blue-gray-6, #F4F6FA);
}
.noUserExistClass{
    color: var(--secondary, #EB5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.userExistClass {
    color: var(--success-green, #34C873);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.retryClass {
    display: flex;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--grays-gray-4, #BDBDBD);
}
.retryFontClass {
    color: var(--grays-grey-3, #828282);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.createNewUserClass {
    display: flex;
    width: 186px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: #219653;
}
.createNewUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.astreikClass {
    color: var(--secondary, #EB5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.dashedLineClassInNewUser {
height: 0px;
border: 1px dashed #BDBDBD;
flex: none;
align-self: stretch;
flex-grow: 0;
}
.addMultipleAddressClass {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 16px;
    gap: 10px;
    width: 240;
    height: 36px;
    background: #48ACEF;
    cursor: pointer;
    border-radius: 4px;
}
.outerUpdateAddressClass {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--grays-blue-gray-6, #F4F6FA);
}
.innerUpdateAddressClass {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 6px;
    background: var(--grays-blue-gray-6, #FFFFFF);
}
.addressIndexClass {
    color: var(--grays-gray-1, #333);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
</style>